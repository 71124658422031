<template lang="pug">
el-card
  template(#header)
    .headerContainer
      span 列表配置
      el-switch(v-model="isShowComponent.feed")
  template(v-if="isShowComponent.feed")
    el-form(label-width="6em")
      el-form-item(label="分类")
        el-select(v-model="feedConfig.source.categoryList" multiple)
          el-option(v-for="i in articleCategoryList" :label="i.name" :value="i._id")
      el-form-item(label="标签")
        jj-tagList(v-model="feedConfig.source.tagList")
      el-form-item(label="排序方式")
        el-select(v-model="feedConfig.sort")
          el-option(v-for="i in articleSortList" :label="i.label" :value="i.value")
      el-form-item(label="默认样式")
        el-radio-group(v-model="feedConfig.style")
          el-radio-button(label="block") 一列
          el-radio-button(label="twoCol") 两列
      el-form-item(label="题图尺寸")
        el-input-number(v-model="feedConfig.posterSize")

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { feedConfig, isShowComponent } from './provider'
import { articleSortList } from '@/dict/article'
import { articleCategoryList, getArticleCategoryList, getCityList, cityList } from '@/provider/global'

const { fetch } = inject('global')

onMounted(() => {
  getArticleCategoryList()
})
</script>

<style lang="less" scoped>
.headerContainer {
  display: flex;
  justify-content: space-between;
}
.page {
  width: 100%;
}
</style>
