import { ref, computed } from 'vue'
import fetch from '@/plugin/axios'
import { ElMessage, ElNotification } from 'element-plus'

export let isShowComponent = ref({
  banner: true,
  navigation: true,
  feed: true
})

export let bannerList = ref([])
export let bannerConfig = ref({
  height: 200,
  alignType: 'cover'
})

export let navigationList = ref([])
export let navigationConfig = ref({
  size: 'middle',
  radius: '10px'
})

export let feedConfig = ref({
  source: {
    type: 'all',
    categoryList: [],
    tagList: []
  },
  sort: '-createTime',
  size: 'middle',
  style: 'block',
  posterSize: 40
})

export function getConfig() {
  fetch.get('/config/homePage/value').then(res => {
    if (res) {
      if (res.bannerList) {
        bannerList.value = res.bannerList
      }
      if (res.bannerConfig) {
        bannerConfig.value = res.bannerConfig
      }
      if (res.navigationList) {
        navigationList.value = res.navigationList
      }
      if (res.navigationConfig) {
        navigationConfig.value = res.navigationConfig
      }
      if (res.feedConfig) {
        feedConfig.value = res.feedConfig
      }
      if (res.isShowComponent) {
        isShowComponent.value = res.isShowComponent
      }
    }
  })
}

export function saveConfig() {
  fetch
    .put('/config/homePage/value', {
      value: {
        bannerList: bannerList.value,
        bannerConfig: bannerConfig.value,
        navigationList: navigationList.value,
        navigationConfig: navigationConfig.value,
        feedConfig: feedConfig.value,
        isShowComponent: isShowComponent.value
      }
    })
    .then(res => {
      ElMessage.success('保存成功')
      getConfig()
    })
}

export let appConfig = ref({
  theme: {
    primaryColor: '',
    successColor: '',
    warningColor: '',
    dangerColor: '',
    textColor: '',
    textColorTitle: '',
    textColorDesc: '',
    borderColor: '',
    backgroundColor: '',
    textSize: 14,
    textSizeTitle: 14,
    textSizeDesc: 14
  },
  bottomBar: {
    color: '',
    borderColor: '',
    flexDirection: 'row',
    isShowIcon: true,
    iconSize: 20,
    iconRadius: 5,
    isShowName: true,
    nameColor: '',
    nameSize: 12,
    list: []
  }
})

export function getAppConfig() {
  fetch.get('/config/appConfig/value').then(res => {
    if (res) {
      if (res.theme) {
        appConfig.value.theme = res.theme
      }
      if (res.bottomBar) {
        appConfig.value.bottomBar = res.bottomBar
      }
    }
  })
}
