<template lang="pug">
el-card
  template(#header)
    .headerContainer
      span 菜单配置
      el-switch(v-model="isShowComponent.navigation")
  template(v-if="isShowComponent.navigation")
    .navigationList
      .item(v-for="(i,n) in navigationList" @dragstart="onDragStart($event,n)" @dragend="onDragEnd($event,n)" @dragover="onDragOver($event,n)" :class="{isDrag:currentDragItemIndex===n,isDragOver:currentDragOverItemIndex===n&&currentDragItemIndex!==n}")
        img.icon(:src="i.icon")
        .name {{ i.name }}
        .actionBar
          el-button(size="mini" circle :type="i.link?'primary':'success'" icon="el-icon-edit" @click="handleEditNavigation(i,n)")
          el-button(size="mini" circle type="danger" icon="el-icon-delete" @click="handleDeleteNavigation(n)")
      .item.additem(@click="handleAdd")
        i.el-icon-plus
    el-form(label-width="8em" inline)
      el-form-item(label="图标尺寸")
        el-input-number(v-model="navigationConfig.iconSize")
      el-form-item(label="图标圆角")
        el-input-number(v-model="navigationConfig.iconRadius")
    el-form(label-width="8em" inline)
      el-form-item(label="文本尺寸")
        el-input-number(v-model="navigationConfig.nameSize")
      el-form-item(label="文本高度")
        el-input-number(v-model="navigationConfig.nameHeight")
      el-form-item(label="文本颜色")
        el-color-picker(size="large" v-model="navigationConfig.nameColor")

  el-dialog(v-model="isShowEditNavigation")
    el-form.editNavigationContainer(label-width="6em")
      el-form-item(label="图标")
        jj-imagePick(v-model="editNavigationData.icon" size="icon")
        el-input(v-model="editNavigationData.icon" style="width:500px")
      el-form-item(label="名称")
        el-input(v-model="editNavigationData.name" style="width:300px")
      el-form-item(label="类型")
        el-radio-group(v-model="editNavigationData.type")
          el-radio-button(label="page") 页面
          el-radio-button(label="articleList") 文章列表
          el-radio-button(label="jobList") 职位列表
          el-radio-button(label="companyList") 公司列表
          el-radio-button(label="miniapp" disabled) 外部小程序
      el-form-item(label="页面地址" v-if="editNavigationData.type === 'page'")
        el-input(v-model="editNavigationData.link")
      template(v-else-if="editNavigationData.type === 'articleList'")
        el-form-item(label="分类")
          el-select(v-model="articleListFilterDataForEdit.categoryList" multiple)
            el-option(v-for="i in articleCategoryList" :label="i.name" :value="i._id")
        el-form-item(label="标签")
          jj-tagList(v-model="articleListFilterDataForEdit.tagList")
        el-form-item(label="排序方式")
          el-select(v-model="articleListFilterDataForEdit.sort")
            el-option(v-for="i in articleSortList" :label="i.label" :value="i.value")
      template(v-else-if="editNavigationData.type === 'jobList'")
        el-form-item(label="职位属性")
          el-select(v-model="articleListFilterDataForEdit.dutyType")
            el-option(v-for="i in dutyTypeList" :label="i" :value="i")
        el-form-item(label="职位分类")
          el-tag(v-if="articleListFilterDataForEdit.jobCategory?._id" type="primary") {{articleListFilterDataForEdit.jobCategory?.name}}
          el-button(style="margin-left:10px" @click="isShowJobCategorySelect=true") 选择
      template(v-else-if="editNavigationData.type === 'companyList'")
        el-form-item(label="标签")
          jj-tagList(v-model="articleListFilterDataForEdit.tagList")
        el-form-item(label="排序方式")
          el-select(v-model="articleListFilterDataForEdit.sort")
            el-option(v-for="i in articleSortList" :label="i.label" :value="i.value")
      el-form-item
        el-button(type="primary" @click="handleSubmitEdit") 保存

  comJobCategorySelect(v-model:isShow="isShowJobCategorySelect" @success="onJobCategorySelect")
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { navigationList, navigationConfig, isShowComponent } from './provider'
import { articleSortList } from '@/dict/article'
import { articleCategoryList, getArticleCategoryList, getCityList, cityList } from '@/provider/global'
import comJobCategorySelect from '@/component/select/jobCategory.vue'
import { dutyTypeList } from '@/provider/dict'

let isShowJobCategorySelect = ref(false)
let isShowEditNavigation = ref(false)
let editNavigationIndex = ref(-1)
let editNavigationData = ref({
  icon: '',
  name: '',
  type: 'page',
  link: ''
})
let articleListFilterDataForEdit = ref({
  categoryList: [],
  tagList: [],
  sort: '',
  dutyType: '',
  jobCategory: {}
})

function onJobCategorySelect(val) {
  articleListFilterDataForEdit.value.jobCategory = val
}

function handleAdd() {
  editNavigationIndex.value = -1
  editNavigationData.value = { icon: '', name: '', type: 'page', link: '' }
  isShowEditNavigation.value = true
}

function handleDeleteNavigation(n) {
  navigationList.value.splice(n, 1)
}
function handleEditNavigation(val, n) {
  editNavigationData.value = { ...val }
  editNavigationIndex.value = n
  isShowEditNavigation.value = true
  if (['articleList', 'jobList', 'companyList'].includes(editNavigationData.value.type)) {
    const queryLsit =
      editNavigationData.value?.link
        ?.split('?')?.[1]
        ?.split('&')
        ?.map(i => ({ k: i?.split('=')?.[0], v: i?.split('=')?.[1] })) || []
    articleListFilterDataForEdit.value.sort = queryLsit.find(i => i.k === 'sort')?.v || ''
    articleListFilterDataForEdit.value.tagList = queryLsit.find(i => i.k === 'tagList')?.v?.split(',') || []
    articleListFilterDataForEdit.value.categoryList = queryLsit.find(i => i.k === 'categoryList')?.v?.split(',') || []
    articleListFilterDataForEdit.value.dutyType = queryLsit.find(i => i.k === 'dutyType')?.v || ''
    articleListFilterDataForEdit.value.jobCategory = queryLsit.find(i => i.k === 'jobCategory')?.v || ''
  }
}

function handleSubmitEdit() {
  if (editNavigationData.value.type === 'articleList') {
    let query = `sort=${articleListFilterDataForEdit.value.sort}`
    if (articleListFilterDataForEdit.value?.tagList?.length) {
      query += `&tagList=${articleListFilterDataForEdit.value.tagList.join(',')}`
    }
    if (articleListFilterDataForEdit.value?.categoryList?.length) {
      query += `&categoryList=${articleListFilterDataForEdit.value.categoryList.join(',')}`
    }
    editNavigationData.value.link = `/pages/article/list/index?${query}`
  } else if (editNavigationData.value.type === 'jobList') {
    let query = `sort=${articleListFilterDataForEdit.value.sort}`
    query += `&dutyType=${articleListFilterDataForEdit.value.dutyType}`
    query += `&jobCategory=${articleListFilterDataForEdit.value.jobCategory._id}`
    editNavigationData.value.link = `/pages/job/list/index?${query}`
  } else if (editNavigationData.value.type === 'companyList') {
    let query = `sort=${articleListFilterDataForEdit.value.sort}`
    if (articleListFilterDataForEdit.value?.tagList?.length) {
      query += `&tagList=${articleListFilterDataForEdit.value.tagList.join(',')}`
    }
    editNavigationData.value.link = `/pages/company/list/index?${query}`
  }
  if (editNavigationIndex.value < 0) {
    navigationList.value.push({
      ...editNavigationData.value
    })
  } else {
    navigationList.value[editNavigationIndex.value] = {
      ...editNavigationData.value
    }
  }
  isShowEditNavigation.value = false
}

function pickImage() {
  window.$globalProp.util.pickImageList({ type: 'system', count: 1 }).then(resultList => {
    if (!resultList.length) return
    editNavigationData.value.icon = resultList[0]
  })
}

let currentDragItemIndex = ref(-1)
let currentDragOverItemIndex = ref(-1)
function onDragStart(e, n) {
  currentDragItemIndex.value = n
}
function onDragEnd(e, n) {
  const sourceItem = navigationList.value[currentDragItemIndex.value]
  navigationList.value.splice(currentDragItemIndex.value, 1)
  if (currentDragOverItemIndex.value === 0) {
    navigationList.value.unshift(sourceItem)
  } else if (currentDragOverItemIndex.value > currentDragItemIndex.value) {
    navigationList.value.splice(currentDragOverItemIndex.value - 1, 0, sourceItem)
  } else {
    navigationList.value.splice(currentDragOverItemIndex.value, 0, sourceItem)
  }
  currentDragItemIndex.value = -1
  currentDragOverItemIndex.value = -1
}
function onDragOver(e, n) {
  currentDragOverItemIndex.value = n
}
</script>

<style lang="less" scoped>
.headerContainer {
  display: flex;
  justify-content: space-between;
}
.navigationList {
  display: flex;
  flex-wrap: wrap;
  width: 470px;
  margin-bottom: 20px;
  .item {
    text-align: center;
    margin: 5px;
    padding: 5px 10px;
    border: 1px dashed var(--color-border);
    position: relative;
    user-select: none;
    cursor: move;
    &:hover {
      border: 1px dashed var(--color-primary);
    }
    .icon {
      width: 70px;
      height: 70px;
      border-radius: 0;
      background: var(--color-background);
    }
    .actionBar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-around;
      padding: 5px;
    }
    &.additem {
      height: 110px;
      width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      border: 1px dashed var(--color-success);
      color: var(--color-success);
      cursor: pointer;
    }
    &.isDrag {
      opacity: 0.4;
    }
    &.isDragOver {
      overflow: unset;
      margin-left: 70px;
      &:before {
        content: '';
        display: block;
        width: 50px;
        background: var(--color-background);
        border: 1px dashed var(--color-primary);
        border-radius: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -70px;
      }
    }
  }
}

.editNavigationContainer {
  .image,
  .addImage {
    width: 100px;
    height: 100px;
  }
  .addImage {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    border: 1px solid var(--color-green);
  }
}
</style>
