<template lang="pug">
.previewContainer
  el-carousel.swiperContainer(:height="bannerConfig.height + 'px'" :interval="bannerConfig.time*1000")
    el-carousel-item(v-for="i in bannerList")
      template(v-if="bannerConfig.alignType==='cover'")
        .image(v-bg.cover="i.image" :style="{ height: bannerConfig.height + 'px' }")
      template(v-else)
        .image(v-bg="i.image" :style="{height:bannerConfig.height+'px'}")
  .br
  .navigationList
    .item(v-for="(i,n) in navigationList")
      img.icon(:src="i.icon" :style="{borderRadius:navigationConfig.iconRadius,width:navigationConfig.iconSize+'px',height:navigationConfig.iconSize+'px'}")
      .name(:style="{color:navigationConfig.nameColor,fontSize:navigationConfig.nameSize+'px',lineHeight:navigationConfig.nameHeight+'px'}") {{ i.name }}
  .br
  .feedList
    .item(v-for="i in 4" :class="feedConfig.style")
      .poster(:style="{height:feedConfig.iconSize+'px'}") poster
      .title(:style="{ color: appConfig.theme.textColorTitle, fontSize: appConfig.theme.textSizeTitle + 'px' }") title bla~bla~bla~bla~bla~bla~bla~bla~bla~bla~
      .desc(:style="{ fontSize: appConfig.theme.textSizeDesc + 'px', color: appConfig.theme.textColorDesc }") desc bla~bla~bla~bla~bla~bla~bla~bla~bla~bla~
      .actionBar
        .btn AA
        .btn BB
        .btn CC
    
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import {
  bannerList,
  bannerConfig,
  navigationList,
  navigationConfig,
  feedConfig,
  isShowComponent,
  appConfig
} from './provider'

const foo = ref('bar')
</script>

<style lang="less" scoped>
.previewContainer {
  background: var(--color-background);
  padding-bottom: 30px;
}
.br {
  height: 5px;
  background: var(--color-background);
}
.swiperContainer {
  .image {
    width: 100%;
    height: 200px;
    background: var(--color-background);
  }
}

.navigationList {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  .item {
    text-align: center;
    margin: 5px;
    padding: 5px 10px;
    cursor: pointer;
    flex-grow: 1;
    &:hover {
      color: var(--color-primary);
    }
    .icon {
      width: 50px;
      height: 50px;
      border-radius: 0;
      background: var(--color-background);
    }
  }
}

.feedList {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin: 20px;
    border: var(--color-border);
    flex-basis: 100%;
    padding-bottom: 20px;
    border-radius: 5px;
    background-color: white;
    position: relative;
    box-shadow: -1px 0 4px var(--color-border);
    .poster {
      .flexCenter();
      background-repeat: no-repeat;
      background-position: cover;
      height: 100px;
      background-color: var(--color-warning);
    }
    .title,
    .desc {
      padding: 10px;
      font-size: 14px;
      color: var(--color-text-title);
      line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
    }
    .actionBar {
      position: absolute;
      left: -10px;
      right: -10px;
      bottom: -20px;
      color: var(--color-primary);
      background: white;
      box-shadow: 1px 1px 4px var(--color-border);
      display: flex;
      border-radius: 30px;
      .btn {
        flex-grow: 1;
        text-align: center;
        border-right: 1px solid var(--color-border);
        line-height: 30px;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .item.twoCol {
    flex-basis: calc(50% - 40px);
    padding-bottom: 10px;
    .desc {
      display: none;
    }
  }
}
</style>
