<template lang="pug">
el-card
  template(#header)
    .headerContainer
      span 横幅配置
      el-switch(v-model="isShowComponent.banner")
  template(v-if="isShowComponent.banner")
    .imageList
      .item(v-for="(i,n) in bannerList" draggable="true" @dragstart="onDragStart($event,n)" @dragend="onDragEnd($event,n)" @dragover="onDragOver($event,n)" :class="{isDrag:currentDragItemIndex===n,isDragOver:currentDragOverItemIndex===n&&currentDragItemIndex!==n}")
        .img(v-bg.cover="i.image")
        .actionBar
          el-button(size="mini" circle :type="i.link?'primary':'success'" icon="el-icon-edit" @click="handleEditItem(n)")
          el-button(size="mini" circle type="danger" icon="el-icon-delete" @click="handleDeleteItem(n)")
      .item.addActionItem(@click.prevent="handleAddImage")
        i.el-icon-plus
    el-form(style="margin-top:20px;")
      el-form-item(label="横幅高度")
        el-input-number(v-model="bannerConfig.height" :step="10")
      el-form-item(label="轮播速度")
        el-input-number(v-model="bannerConfig.time" :step="1")
      el-form-item(label="图片样式")
        el-radio-group(v-model="bannerConfig.alignType")
          el-radio-button(label="center") 居中
          el-radio-button(label="cover") 铺满

  el-dialog(v-model="isShowEditForm" title="编辑横幅链接")
    el-form(label-width="6em")
      el-form-item(label="图片")
        el-input(v-model="editFormData.image")
      el-form-item(label="type")
        el-radio-group(v-model="editFormData.type")
          el-radio-button(label="page") 页面
          el-radio-button(label="article") 文章
          el-radio-button(label="company" disabled) 公司
          el-radio-button(label="job" disabled) 职位
      el-form-item(:label="editFormData.type")
        el-input(v-model="editFormData.link")
      el-form-item
        el-button(type="primary" @click="handleSubmitEdit") 保存
        
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { bannerList, bannerConfig, isShowComponent } from './provider'
import { ElMessageBox, ElMessage } from 'element-plus'

// function handleAddImage() {
//   window.$globalProp.util.pickImageList({ type: 'commodity', count: 9 }).then(resultList => {
//     if (!resultList.length) return
//     resultList.forEach(fileUrl => {
//       const n = bannerList.value.findIndex(i => i.image === fileUrl)
//       if (n < 0) {
//         bannerList.value.push({ image: fileUrl, type: '', link: '' })
//       }
//     })
//   })
// }
function handleAddImage() {
  ElMessageBox.prompt('输入图片地址', 'Tip', {
    confirmButtonText: '确定',
    cancelButtonText: '取消'
  }).then(({ value }) => {
    const n = bannerList.value.findIndex(i => i.image === value)
    if (n < 0) {
      bannerList.value.push({ image: value, type: '', link: '' })
    }
  })
}

let isShowEditForm = ref(false)
let editFormData = ref({
  index: -1,
  image: '',
  type: '',
  link: ''
})
function handleEditItem(n) {
  editFormData.value.image = bannerList.value[n].image
  editFormData.value.type = bannerList.value[n].type
  editFormData.value.link = bannerList.value[n].link
  editFormData.value.index = n
  isShowEditForm.value = true
}
function handleSubmitEdit() {
  if (editFormData.value.index < 0) return
  bannerList.value[editFormData.value.index] = {
    image: editFormData.value.image,
    type: editFormData.value.type,
    link: editFormData.value.link
  }
  isShowEditForm.value = false
}

function handleDeleteItem(n) {
  bannerList.value.splice(n, 1)
}

let currentDragItemIndex = ref(-1)
let currentDragOverItemIndex = ref(-1)
function onDragStart(e, n) {
  currentDragItemIndex.value = n
}
function onDragEnd(e, n) {
  const sourceItem = bannerList.value[currentDragItemIndex.value]
  bannerList.value.splice(currentDragItemIndex.value, 1)
  if (currentDragOverItemIndex.value === 0) {
    bannerList.value.unshift(sourceItem)
  } else if (currentDragOverItemIndex.value > currentDragItemIndex.value) {
    bannerList.value.splice(currentDragOverItemIndex.value - 1, 0, sourceItem)
  } else {
    bannerList.value.splice(currentDragOverItemIndex.value, 0, sourceItem)
  }
  currentDragItemIndex.value = -1
  currentDragOverItemIndex.value = -1
}
function onDragOver(e, n) {
  currentDragOverItemIndex.value = n
}
</script>

<style lang="less" scoped>
.headerContainer {
  display: flex;
  justify-content: space-between;
}
.imageList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  * {
    transition: all 0.2s ease;
  }
  .item {
    width: 400px;
    height: 200px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: move;
    &:hover {
      border: 1px solid var(--color-primary);
    }

    .img {
      width: 200px;
      height: 200px;
    }
    .actionBar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-around;
      padding: 5px;
    }
    &.addActionItem {
      font-size: 60px;
      cursor: pointer;
      &:hover {
        color: var(--color-green);
        border: 1px solid var(--color-green);
      }
    }
    &.isDrag {
      opacity: 0.4;
    }
    &.isDragOver {
      overflow: unset;
      margin-left: 70px;
      &:before {
        content: '';
        display: block;
        width: 50px;
        background: var(--color-background);
        border: 1px dashed var(--color-primary);
        border-radius: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -70px;
      }
    }
  }
}
</style>
