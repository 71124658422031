import { ref, computed } from 'vue'
import fetch from '@/plugin/axios'

export const dutyTypeList = ref(['全职', '兼职', '远程'])
export const benefitList = ref([
  '五险一金',
  '年底双薪',
  '包吃',
  '包住',
  '带薪年假',
  '通讯补助',
  '交通补助',
  '免费班车',
  '员工旅游'
])
