<template lang="pug">
.page
  .leftContainer
    com-banner
    com-navigation
    com-feed
  el-card.rightContainer(:body-style='{ padding: "0px" }')
    template(#header)
      .headerContainer
        span 预览
        el-button(type="primary" @click="saveConfig") 保存
    com-preview

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import comBanner from './__com__/banner.vue'
import comNavigation from './__com__/navigation.vue'
import comFeed from './__com__/feed.vue'
import comPreview from './__com__/preview.vue'
import { saveConfig, getConfig, getAppConfig } from './__com__/provider'

onMounted(() => {
  getConfig()
  getAppConfig()
})
</script>

<style lang="less" scoped>
.headerContainer {
  display: flex;
  justify-content: space-between;
}
.page {
  display: flex;
  align-items: flex-start;
  .leftContainer {
    flex-grow: 1;
    margin-right: 20px;
    .el-card {
      margin-bottom: 20px;
    }
  }
  .rightContainer {
    flex-grow: 0;
    flex-shrink: 0;
    width: 470px;
  }
}
</style>
